<template>
  <div>
    <div class="tutor-mian ">
      <div>
       <template v-if="language=='zh'">
        <myimage :url='url'></myimage>
      </template>
      <template v-if="language=='TW'">
        <myimage :url='urlTW'></myimage>
      </template>
      <template v-if="language=='En'">
        <myimage :url='urlEn'></myimage>
      </template>
      </div>
      <div :class="screenWidth>=992?'tutor-service':'tutor-service-sm' ">
        <div class="tutor-top">
          <img src="../../assets/image/tutor/top1.png" alt="">
        </div>
      </div>
      <div class="backgroundAF6">
        <div :class="screenWidth>=992?'tutor-service':'tutor-service-sm' ">
          <div class="backgroundAF6-serve">
            <div class="english-yellow">{{$t('tutor.Famousteacherguidance')}}</div>
            <div class="service-con">
              <div class="servicetop">{{$t('tutor.名师辅导')}}</div>
              <div class="servicebot"></div>
            </div>
            <div>
              <img src="../../assets/image/tutor/top2.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="background8f8">
        <div :class="screenWidth>=992?'tutor-service':'tutor-service-sm' " style="padding-top: 50PX ;">
          <div class="background8f8-serve">
            <div class="english-yellow">{{$t('tutor.Coachingprocess')}}</div>
            <div class="service-con">
              <div class="servicetop">{{$t('tutor.辅导流程')}}</div>
              <div class="servicebot"></div>
            </div>
            <div>
              <img src="../../assets/image/tutor/top3.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div :class="screenWidth>=992?'tutor-service':'tutor-service-sm' " style="padding-top: 50PX ;">
        <div class="background-serve">
          <div class="english-yellow">{{$t('tutor.Serviceadvantage')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('tutor.服务优势')}}</div>
            <div class="servicebot"></div>
          </div>
          <div>
            <img src="../../assets/image/tutor/top4.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myimage from '@/components/myimage'

export default {
  components: {
    myimage
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      // bannerList: [
      //   'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e',
      //   'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e',
      //   'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e'
      // ],
      language:'zh',
      url:require('@/assets/image/kcfd.png'),
      urlTW: require('@/assets/image/kcfdTW.png'),
      urlEn: require('@/assets/image/kcfdEn.png'),
    }
  },
  created () {
    this.language=localStorage.getItem('language');
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
  },
}
</script>

<style lang="scss" scoped>
.tutor-mian {
  width: 100vw;

}

.tutor-service-sm {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.tutor-service {
  width: 75%;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.tutor-service > div, .tutor-service-sm > div {
  position: relative;
  top: 0;
}

.tutor-top img {
  width: 1176px;
  padding: 70px 0;
}

.backgroundAF6 {
  background: #FEFAF6;
  padding: 70px 0;
}

.backgroundAF6-serve {
  height:500px;
}

.backgroundAF6-serve img {
  width: 1176px;
  height:328px;
  margin-top: 100PX;
}

.english-yellow {
  color: #FE992B;
  font-size: 44px;
  opacity: 0.3;
}

.service-con {
  width: 100%;
  position: absolute;
  top: 15px;
}

.servicetop {

  font-size: 32px;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
}

.servicebot {
  width: 150px;
  height: 6PX;
  background: #FE992B;
  border-radius: 3px;
  margin: 0 auto;
}

.background8f8 {
  background: url('../../assets/image/tutor/backg.png');
  background-size: 100% 581PX;

}

.background8f8-serve img {
  width: 1176px;
  height: 298px;
  margin-top: 100PX;
}

.background-serve img {
  height: 375px;
  width:1176px;
  margin-top: 100PX;
}
</style>